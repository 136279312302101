import { initializeApp } from 'firebase/app';
import { getAuth, signOut, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential, fetchSignInMethodsForEmail } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
//import { getDatabase} from "firebase/database"; //Should not be used on front anyway..

import { getAnalytics } from "firebase/analytics";
import { getConfig, checkMscConfig } from "./config";
import { getProperty, sleep } from "../util";

// Default region for all functions
//
// var region = "us-central1";
var region = 'europe-west1';

class Firebase {
  constructor() {
    var firebaseConfig = getConfig();
    // Check if in other domain and db courses
    var replaceDblink = checkMscConfig();
    if (replaceDblink !== false) {
      // change right database url to settings
      firebaseConfig.databaseURL = replaceDblink;
    }
    const useFunctionsEmulator =
      process.env.NODE_ENV === "development" && process.env.REACT_APP_EMULATOR_PORT !== undefined;

    if (firebaseConfig != null) {
      const app = initializeApp(firebaseConfig);
      this.edukamuApp = app;
      // modular style
      // firebase emulators:start --only functions
      // REACT_APP_EMULATOR_PORT=5001 npm start
      const functions = getFunctions(app, region);

      if (useFunctionsEmulator) {
        connectFunctionsEmulator(functions, `localhost`, process.env.REACT_APP_EMULATOR_PORT);
      }
      this.functions = functions;
      this.auth = getAuth(app);

      this.auth.languageCode = window.EDUKAMU_SETTINGS ? window.EDUKAMU_SETTINGS.lang : "en";
      // Ehkäpä näin modulaarisesti??
      // this.analytics =  getAnalytics(app);
      this.storage = getStorage(app);

    } else {
      console.error("No firebase config available! Cannot initialize database");
    }

    this.userAnswers = {};
    this.userAnswersCallbacks = [];
    this.registrationCallbacks = [];
    this.progressCallbacks = [];
    this.isLoading = true;

    onAuthStateChanged(this.auth, authUser => {
      if (authUser) {
        const currentcourseid = window?.EDUKAMU_SETTINGS?.courses[0]?.id;
        if (currentcourseid) {
          this.initAnswers(currentcourseid);
        }
      } else {
        this.clearAnswers();
      }
    });
  }

  getCurrentUser = () => this.auth.currentUser;

  /**
   * Refresh current user, if signed in
   * @returns
   */
  reloadUser = async () => {
    if (!this.auth?.currentUser) return;

    await this.auth.currentUser.reload();

    return;
  };

  /**
   * Check if email is already taken
   *
   * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#fetchsigninmethodsforemail
   * returns empty array, if email is available
   * @param {*} email
   * @returns {boolean}
   */
  isEmailTaken = async (email) => {


    const result = await fetchSignInMethodsForEmail(this.auth, email);

    if (result.length === 0) return false;

    return true;
  };

  /**
   * Create new user
   * @param {{ email: string, password: string }} user - User data
   * @returns
   */
  createUser = async (user = {}) => {
    const { email, password, profile, ...rest } = user;
    let cred;

    try {
      cred = await createUserWithEmailAndPassword(this.auth, email, password);
      // Update user profile in backend
      // Throws 'auth/too-many-requests' if updated from client after user is created
      if (rest) await this.updateFirebaseUser(rest);
      if (profile) await this.updateUser(profile);
    } catch (authError) {
      return {
        newUser: null,
        error: { ...authError },
      };
    }

    return { newUser: cred.user, error: null };
  };

  /**
   * Update firebase user profile
   *
   * @param {*} userData
   * @returns
   */
  updateFirebaseUser = async (userData) => {
    return new Promise((resolve, reject) => {
      const func = httpsCallable(this.functions, "updateFirebaseUser_gen2");
      func({ userData })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  /**
   * Attempt to reauthenticate the current user.
   * May be required before security sensitive operations.
   * (any op returning code "auth/requires-recent-login")
   *
   * For return error codes, see:
   * https://firebase.google.com/docs/reference/js/v8/firebase.User#reauthenticatewithcredential
   * @param {string} password - User password
   * @returns {{
   *  newUser: null | app.User,
   *  error: null | {
   *    code: string,
   *    message: string
   *  }
   * }}
   */
  reauthenticate = async (password) => {
    if (typeof password !== "string" || password.length <= 0) {
      return {
        newUser: null,
        error: {
          code: "custom/no-password",
          message: "password must be string of length greater than 0",
        },
      };
    }

    if (!this.auth.currentUser) {
      return {
        newUser: null,
        error: {
          code: "custom/no-user",
          message: "no current user to reauthenticate as",
        },
      };
    }

    try {
      const cred = EmailAuthProvider.credential(this.auth.currentUser.email, password);
      const result = await reauthenticateWithCredential(this.auth.currentUser, cred);
      return {
        newUser: result.user,
        error: null,
      };
    } catch (authError) {
      console.error("Failed to reauthenticate: unknown");
      console.error(JSON.stringify(authError));
      return {
        newUser: null,
        error: authError,
      };
    }
  };

  /**
   * Get initial data from Firebase.
   */
  initAnswers = async (course_id) => {
    var user = this.auth.currentUser;
    if (!user) {
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    this.userAnswers = {};

    // format
    let answers = await this.getAllUserCourseAnswers(course_id);
    for (const key of Object.keys(answers)) {
      this.addAnswer(answers[key]);
    }

    this.isLoading = false;

    this.updateAnswerCallbacks();
  };

  /**
   * Add answer to state.
   * Format from V2 to V1 so we don't need to change existing code.
   * @param {*} answer
   */
  addAnswer = (answer) => {
    if (!answer.course_id || !answer.question_id || !answer.data) {
      console.error("firebase.addAnswer invalid answer:");
      console.error(JSON.stringify(answer, null, 2));
    }

    if (!this.userAnswers[answer.course_id]) {
      this.userAnswers[answer.course_id] = {};
    }

    this.userAnswers[answer.course_id][answer.question_id] = {
      ...answer,
    };
  };

  /**
   * Reset Firebase data.
   */
  clearAnswers = async () => {
    this.userAnswers = {};
    this.isLoading = false;
    this.updateAnswerCallbacks();
  };

  /**
   * Invoke callbacks subscribed to 'userAnswers' changes.
   * Should be called when fetching initial data from Firebase,
   * and after submitting new answers, etc.
   */
  updateAnswerCallbacks = () => {
    for (const cb of this.userAnswersCallbacks) {
      cb(this.userAnswers);
    }
  };

  /**
   * Get ALL course answers from 'database' for signed in user.
   * @returns {Promise<*>}
   */
  getAllUserCourseAnswers = (course_id) => {
    return new Promise((resolve, reject) => {
      var get = httpsCallable(this.functions, "getAllUserCourseAnswers_gen2");
      get({ course_id: course_id })
        .then((result) => {
          if (result?.data) {
            return resolve(result.data);
          }
          resolve({});
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  activateGoogleAnalytics = () => {
    if (this.analytics) {
      this.analytics.setAnalyticsCollectionEnabled(true);
      return;
    }

    this.analytics = getAnalytics(this.edukamuApp);// app.analytics();
  };

  disableGoogleAnalytics = () => {
    if (!this.analytics) {
      return;
    }

    this.analytics.setAnalyticsCollectionEnabled(false);
  };

  /**
   * deprecated?
   *
   * @param {*} email
   * @param {*} password
   */
  doSignInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve) => {
      signInWithEmailAndPassword(this.auth, email, password).then(() => {
        resolve();
      });
    });
  };

  verifyUser = () => {
    return new Promise((resolve, reject) => {
      const get = httpsCallable(this.functions, "verifyUser_gen2");
      get()
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  reserveJupyterPort = (course) => {
    return new Promise((resolve, reject) => {
      const func = httpsCallable(this.functions, "reserveJupyterPort_gen2");
      func({ course: course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("reserveJupyterPort: Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  reserveMatlabContainer = (course) => {
    return new Promise((resolve, reject) => {
      const func = httpsCallable(this.functions, "reserveMatlabContainer_gen2");
      func({ course: course }).then((result) => {
        if (result.data) {
          return resolve(result.data);
        }
        reject("reserveMatlabContainer: Invalid data");
      })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateUserInformation = (courseid) => {
    return new Promise((resolve, reject) => {
      const get = httpsCallable(this.functions, "updateUserInformation_gen2");
      get({ course: courseid })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * Sign out current user
   */
  doSignOut = () => {
    return new Promise((resolve) => {
      signOut(this.auth).then(() => {
        resolve();
      });      
    });
  };

  /**
   *
   * @param {*} uid
   */
  async getUserById(uid) {
    return new Promise((resolve, reject) => {
      const get = httpsCallable(this.functions, "getUserByID_gen2");
      get()
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          return null;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} courseid
   */
  async deleteUserInfo(courseid) {
    return new Promise((resolve, reject) => {
      const get = httpsCallable(this.functions, "deleteUserInfo_gen2");
      get({ course: courseid })
        .then((result) => {
          if (result.data) {
            this.initAnswers();
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @deprecated Not inplemeneted Yet
   * @param {*} courseID
   * @param {*} userID
   * @returns Promise.reject()
   */
  async deleteUser(courseID, userID) {
    return Promise.reject("Invalid data");
    /*
    return new Promise((resolve, reject) => {
      
      const func = httpsCallable (functions,"deleteUser");
      func({ course: courseID, id: userID })
        .then((result) => {
          if (result.data && result.data === true) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
    */
  }

  /**
   * Update user info, used in profile modal.
   *
   * @param {*} uid
   * @param {*} data
   * @returns {*}
   */
  async updateUser(userData) {
    return new Promise((resolve, reject) => {
      const func = httpsCallable(this.functions, "updateUser_gen2");
      func({ userData })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Set by course and question id and custom data-object
   * @param {*} course
   * @param {*} question
   * @param {*} answer
   */
  setUserAnswerData(course, question, answer = null) {
    return new Promise((resolve, reject) => {
      var setUserAnswerData = httpsCallable(this.functions, "setUserAnswerData_gen2");
      setUserAnswerData({ course: course, question: question, answer: answer })
        .then((result) => {
          if (result.data) {
            this.addAnswer(result.data);
            this.updateAnswerCallbacks();
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get user answer by course and question id.
   * @param {string} course
   * @param {string} question
   * @returns {*|null}
   */
  getUserAnswerData = async (course, question) => {
    while (this.isLoading) {
      await sleep(100);
    }

    return getProperty(`${course}.${question}`, this.userAnswers);
  };

  /**
   * Watch 'v2_user_answers' table for changes
   * @param {function} cb callback to pass data to
   */
  watchAnswers = (cb) => {
    var user = this.auth.currentUser;
    if (!user) {
      return;
    }

    if (typeof cb !== "function") {
      console.error("firebase.watchAnswers callback must be a function");
      return;
    }

    this.userAnswersCallbacks.push(cb);

    if (!this.isLoading) {
      cb(this.userAnswers);
    }
  };

  /**
   * Watch 'v2_user_answers' table for changes
   * @param {function} cb callback to pass data to
   */
  stopWatchingAnswers = (cb) => {
    var user = this.auth.currentUser;
    if (!user) {
      return;
    }

    const index = this.userAnswersCallbacks.findIndex((f) => f === cb);
    if (index >= 0) {
      this.userAnswersCallbacks.splice(index, 1);
    }
  };

  /**
   * Watch 'v2_user_progress/{uid}_{courseId}/' table for changes
   * @param {*} courseId
   * @param {*} cb callback to pass data to
   * @returns Funtion to stop watching
   */

  watchCourseProgress = (courseId, cb) => {
    var user = this.auth.currentUser;
    if (!user) {
      return () => { };
    }
    if (typeof cb !== "function") {
      console.error("firebase.watchCourseProgress callback must be a function");
      return;
    }

    this.progressCallbacks.push(cb);
    this.getUserProgress(courseId);
  };

  stopWatchingProgress = (course, cb) => {
    var user = this.auth.currentUser;
    if (!user) {
      return;
    }
    const index = this.progressCallbacks.findIndex((f) => f === cb);
    if (index >= 0) {
      this.progressCallbacks.splice(index, 1);
    }
  }

  /**
   *
   * @param {*} course
   * @param {*} question
   * @param {*} selection
   */
  async setCourseSelections(course, question, selection = null) {
    return new Promise((resolve, reject) => {

      const set = httpsCallable(this.functions, "setCourseSelections_gen2");
      set({ course, question, selection })
        .then((result) => {
          if (result.data) {
            if (this.progressCallbacks.length > 0) { this.getUserProgress(course); }
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} course
   * @param {*} resource
   * @param {*} resourceValue
   */
  async setUserProgress(course, resource, resourceValue = null) {
    return new Promise((resolve, reject) => {

      const set = httpsCallable(this.functions, "setUserProgress_gen2");
      set({ course: course, resource: resource, resourceValue: resourceValue })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @deprecated
   * @param {*} course
   * Palauttaa nyt aina promise.resolve([])
   */
  async getUsers(course) {
    /*    return new Promise((resolve, reject) => {
      
      const get = httpsCallable(this.functions,"getUsers");
      get({ course: course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
 */
    return Promise.resolve([]);
  }

  /**
   *
   */
  async getCourseFeedback(course, feedbackGroups) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseFeedback_gen2");

      get({ course: course, feedback: feedbackGroups })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get data about videos that require manual grading from course teacher.
   * @param {*} course - Course ID
   * @param {*} questions - Question IDs
   * @returns
   */
  async getCourseVideoGrading(course, questions) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseVideoGrading_gen2");
      get({ course: course, questions: questions })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Mark video submission as passed / failed.
   * @param {*} course - Course ID
   * @param {*} question - Question ID
   * @param {*} user - Student user ID
   * @param {*} passed - Passed?
   * @returns Data in 'user_answers' table
   */
  async setCourseVideoGrading(course, question, user, passed) {
    return new Promise((resolve, reject) => {

      const set = httpsCallable(this.functions, "setCourseVideoGrading_gen2");
      set({ course: course, question: question, user: user, passed: passed })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Set grade for exercise
   * @param {*} course - Course ID
   * @param {*} question - Question ID
   * @param {*} user - Student user ID
   * @param {*} grade - grade object
   * @returns Data in 'user_answers' table
   */
  async setCourseExerciseGrading(course, question, user, grade) {
    return new Promise((resolve, reject) => {

      const set = httpsCallable(this.functions, "setCourseExerciseGrading_gen2");
      set({ course: course, question: question, user: user, grade: grade })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Reset user attempt count for question
   * @param {string} course - Course ID
   * @param {string} question - Question ID
   * @param {string} user - User ID
   * @returns
   */
  async resetExercise(course, question, user) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "resetExercise_gen2");
      func({ course: course, question: question, user: user })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} course
   */
  async getUsersVouchers(course) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUsersVouchers_gen2");
      get({ course: course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} course
   */
  async getCourseAccomplishments(course) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseAccomplishments_gen2");
      get({ course: course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} course
   */
  async getCourseInProgressStudents(course, courseContentUrl) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseInProgressStudents_gen2");
      get({ course, courseContentUrl })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} course
   */
  async getCourseAccomplishments2(course) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseAccomplishments2_gen2");
      get({ course: course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} course
   * @param {*} resource
   */
  async getUserProgress(course, resource = null) {
    if (!course) { return; }
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserProgress_gen2");
      get({ course: course, resource: resource })
        .then((result) => {
          if (result.data) {
            for (const cb of this.progressCallbacks) {
              cb(result.data);
            }
            this.updateAnswerCallbacks();
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} course
   */
  async getCourseUser(course) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseUser_gen2");
      get({ course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Sets the latest saved exercise location to users progress
   * @param {string} course - course id
   * @param {Object} location - includes all the information to find the component
   * @returns
   */
  async setLatestUserProgress(course, location) {
    return new Promise((resolve, reject) => {

      const setProgress = httpsCallable(this.functions, "setLatestUserProgress_gen2");

      setProgress({ course, location })
        .then((result) => {
          if (result.data) {
            return resolve("Progress checkpoint set");
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Sets a tag for the user for this course
   * @param {string} course - course id
   * @param {Object} tag - includes tag related information
   * @returns
   */
  async setCourseTag(course, tag) {
    return new Promise((resolve, reject) => {

      const setCourseTag = httpsCallable(this.functions, "setCourseTag_gen2");

      setCourseTag({ course, tag })
        .then((result) => {
          if (result.data) {
            return resolve("Tag saved");
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Sets a tag for the user for this course
   * @param {string} course - course id
   * @param {Object} note - includes note related information
   * @returns
   */
  async setCourseNote(course, note) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "setCourseNote_gen2");

      func({ course, note })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} course
   */
  async hasAccess(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "hasAccess_gen2");
      func({ course: course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} username
   * @param {*} password
   * @param {*} domain
   */
  async siteLogin(username, password, domain) {
    return new Promise((resolve, reject) => {
      // always use edukamu firebase project

      const func = httpsCallable(this.functions, "siteLogin_gen2");
      func({ username: username, password: password, domain: domain })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            return resolve(result.data);
          }
          reject(
            "Jokin meni pieleen. Ole hyvä ja yritä uudelleen myöhemmin. Jos ongelma jatkuu, ota yhteyttä järjestelmän ylläpitäjään."
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} token
   */
  async checkAccessToken(token) {
    return new Promise((resolve, reject) => {
      // always use edukamu firebase project

      const func = httpsCallable(this.functions, "checkAccessToken_gen2");
      func({ token: token })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            return resolve(result.data);
          }
          reject(
            "Jokin meni pieleen. Ole hyvä ja yritä uudelleen myöhemmin. Jos ongelma jatkuu, ota yhteyttä järjestelmän ylläpitäjään."
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Check if user has a valid voucher for the course
   * @param {*} course - Course ID
   */
  async hasValidVoucher(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "hasValidVoucher_gen2");
      func({ course: course })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Attempt to redeem a voucher
   * @param {*} course - Course ID
   * @param {*} voucher - Voucher ID
   */
  async redeemVoucher(course, voucher) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "redeemVoucher_gen2");
      func({ course: course, voucher: voucher })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} courseid
   */
  async deleteUserRegistration(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "deleteUserRegistration_gen2");
      func({ course: course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} courseid
   */
  async deleteUserVoucher(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "deleteUserVoucher_gen2");
      func({ course: course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get registration info for course (read only)
   * @param {*} course - course id
   * @returns registration info
   */
  async getRegistration(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "getRegistration_gen2");
      func({ course: course })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            for (const cb of this.registrationCallbacks) {
              cb(result.data);
            }
            return resolve(result.data);
          }
          reject("invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Watch user/course table,
   * used for registration info
   * @param {*} course - course id
   * @returns course data
   */
  async watchUserCourse(course, cb) {
    if (typeof cb !== "function") {
      throw Error("callback is not a function");
    }

    let user = this.auth.currentUser;
    if (!user) {
      cb(null);
    }

    this.registrationCallbacks.push(cb);
  }

  stopWatchingUserCourse = (course, cb) => {
    var user = this.auth.currentUser;
    if (!user) {
      return;
    }
    const index = this.registrationCallbacks.findIndex((f) => f === cb);
    if (index >= 0) {
      this.registrationCallbacks.splice(index, 1);
    }
  };

  /**
   * @deprecated
   * Check (update) registration info for course
   * @param {} course - course id
   * @returns registration info
   */
  async checkRegistration(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "checkRegistration_gen2");
      func({ course: course })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            for (const cb of this.registrationCallbacks) {
              cb(result.data);
            }
            return resolve(result.data);
          }
          reject("invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Set registration info for course
   * @param {*} course - course id
   * @param {*} now - save registration now or later?
   * @returns {boolean} Success
   */
  async setRegistration(course, now) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "setRegistration_gen2");
      func({ course: course, now: now })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            for (const cb of this.registrationCallbacks) {
              cb(result.data);
            }
            return resolve(result.data);
          }
          reject("invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} email
   * @param {*} phoneNumber
   * @param {*} contactType
   * @param {*} role
   */
  async addContactRequest(email, phoneNumber, contactType, role) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "addContactRequest_gen2");
      func({ email: email, phoneNumber: phoneNumber, contactType: contactType, role: role })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {*} name
   * @param {*} email
   * @param {*} company
   * @param {*} message
   */
  async sendContactForm(name, email, company, message) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "sendContactForm_gen2");
      func({ name: name, email: email, company: company, message: message })
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @param {*} userName - Student name
   * @param {*} userEmail - Student name
   * @param {*} question - Question title
   * @param {*} answer - Given answer
   * @param {*} course - Course name
   * @param {*} recipient - Recipients email address
   * @param {*} subject - Question title
   * @param {*} message - Included message
   */
  async sendTextPollAnswer(userName, userEmail, question, answer, course, recipient, subject, message) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "sendTextPollAnswer_gen2");

      func({
        userName,
        userEmail,
        question,
        answer,
        course,
        recipient,
        subject,
        message,
      })
        .then((result) => {
          if (result.data === true) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *  @param {*} date - Assignments return date
   *  @param {*} userEmail - Users email
   *  @param {*} userName - Users name
   *  @param {*} boxName - Components name
   *  @param {*} returnType - Return type as string
   *  @param {*} course - Course name
   *  @param {*} recipient - Recipients email address
   *  @param {*} subject - Email subject
   *  @param {*} text - Extra message
   *  @param {*} files - files returned [{ filename: xxx, path: xxx }]
   *
   */
  async sendReturnedAssignment(
    date,
    userEmail,
    userName,
    boxName,
    returnType,
    course,
    recipient,
    subject,
    text,
    files
  ) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "sendReturnedAssignment_gen2");

      func({
        date,
        userEmail,
        userName,
        boxName,
        returnType,
        course,
        recipient,
        subject,
        text,
        files,
      })
        .then((result) => {
          if (result.data === true) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Sends edukamu-exam components results by email
   * @param {Object} emailData
   * @param {string} emailData.recipient
   * @param {string} emailData.subject
   * @param {Object} emailData.score
   * @param {number} emailData.score.points
   * @param {number} emailData.score.maxPoints
   * @param {string} [emailData.greeting ]
   * @param {string} [emailData.body]
   * @param {string} [emailData.closing]
   * @param {string} [emailData.feedbackMessage]
   * @param {string} [emailData.resultMessage]
   * @returns
   */
  async sendExamResults(emailData = {}) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "sendExamResults_gen2");

      func(emailData)
        .then((result) => {
          if (result.data !== null && result.data !== undefined) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async startTimedExercise(course, question, resetTimeMinutes, additionalData) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "startTimedExercise_gen2");

      func({ course, question, resetTimeMinutes, additionalData })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveExerciseData(course, question, url, answers = null, additionalData = null) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "saveExerciseData_gen2");

      func({ url, course, question, userAnswers: answers, additionalData })
        .then((res) => {
          if (res?.data) {
            this.addAnswer({
              question_id: question,
              course_id: course,
              data: res.data,
            });
            this.updateAnswerCallbacks();

            if (this.progressCallbacks.length > 0) { this.getUserProgress(course); }
          }

          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveReturnBoxData(course, question, answer) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "saveReturnBoxData_gen2");

      func({ course, question, answer })
        .then((res) => {
          if (res?.data) {
            this.addAnswer(res.data);
            this.updateAnswerCallbacks();
            if (this.progressCallbacks.length > 0) { this.getUserProgress(course); }
          }

          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async checkIndividualAnswer(url, course, answer) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "checkIndividualAnswer_gen2");

      func({ url, course, userAnswers: answer })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getExerciseData(url, courseId) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "getExerciseData_gen2");

      func({ url, courseId })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Upload file to storage bucket under current user
   * @param {(File|Blob|Uint8Array)} data - The data to upload
   * @param {string} path - Path relative to {bucket}/user/{userId}/
   * @param {(object)} metaData - Metadata for uploaded object
   * @param {function} eventCallback - Callback function, params: (Error?, Snapshot?)
   */
  uploadToUserStorage(data, path, metaData = {}, eventCallback) {
    // Callback helper
    const call = (error, snapshot, url) => {
      if (eventCallback && typeof eventCallback === "function") {
        eventCallback(error, snapshot, url);
      }
    };

    var user = this.auth.currentUser;
    if (!user) {
      console.error("Calling uploadToUserStorage with no user");
      call(Error("No user"), null, null);
      return;
    }

    if (typeof path !== "string") {
      console.error("Calling uploadToUserStorage with invalid path");
      console.error(JSON.stringify(path, null, 2));
      call(Error("Invalid path"), null, null);
      return;
    }

    const storageRef = ref(this.storage, `user/${user.uid}/${path}`)
    //.put(data, metaData);

    const uploadTask = uploadBytesResumable(storageRef, data, metaData);
    //.put(data, metaData);
    uploadTask.on(
      "state_changed",
      // On state change
      (snapshot) => {
        //let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        call(null, snapshot, null);
      },

      // On error
      (error) => {
        console.error(`Failed to upload ${path}`);
        console.error(error);
        call(error, null, null);
      },

      // On complete
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          call(null, uploadTask.snapshot, url);
        });
      }
    );
  }

  /**
   * Promisified version of uploadToUserStorage.
   * Resolves with object url on succesful upload,
   * reject otherwise.
   * @param {(File|Blob|Uint8Array)} data - The data to upload
   * @param {string} path - Path relative to {bucket}/user/{userId}/
   * @param {(object)} metaData - Metadata for uploaded object
   */
  uploadToUserStoragePromise(data, path, metaData = {}) {
    if (typeof path !== "string") {
      console.error("Calling uploadToUserStoragePromise with invalid path");
      console.error(JSON.stringify(path, null, 2));
      return new Promise((resolve, reject) => {
        reject(Error("Invalid path"));
      });
    }

    return new Promise((resolve, reject) => {
      this.uploadToUserStorage(data, path, metaData, (error, snapshot, url) => {
        if (error) {
          console.error(error);
          reject(error);
          return;
        }

        if (snapshot) {
          if (snapshot.state === "success" && url) {
            resolve(url);
          } else if (snapshot.state === "error" || snapshot.state === "cancelled") {
            reject(snapshot);
          }
        }
      });
    });
  }

  /**
   * Get the URL to a storage object stored under current user
   * @param {string} path - Path relative to {bucket}/user/{userId}/
   * @returns {Promise} Promise that resolves to URL pointing to the storage object
   */
  getUserStorageUrl(path, uid) {
    var user = this.auth.currentUser;

    if (!user) {
      console.error("Calling getUserStorageUrl with no user");
      return new Promise((resolve, reject) => {
        reject(Error("No user"));
      });
    }

    if (typeof path !== "string") {
      console.error("Calling getUserStorageUrl with invalid path");
      console.error(JSON.stringify(path, null, 2));
      return new Promise((resolve, reject) => {
        reject(Error("Invalid path"));
      });
    }

    const refe = ref(this.storage, `user/${uid ?? user.uid}/${path}`);
    var url = getDownloadURL(refe).then((dlurl) => {
      return dlurl;
    });
    return url;
  }

  /**
   * Get the URL to a storage object starting from bucket root.
   * Will reject if user has no permission to read the path.
   * @param {string} path - Storage path
   * @returns {Promise} Promise that resolves to URL pointing to the storage object
   */
  getAbsoluteStorageUrl(path) {
    if (typeof path !== "string") {
      console.error("Calling getStorageUrl with invalid path");
      console.error(JSON.stringify(path, null, 2));
      return new Promise((resolve, reject) => {
        reject(Error("Invalid path"));
      });
    }

    const refe = ref(this.storage, path);
    var url = getDownloadURL(refe).then((dlurl) => {
      return dlurl;
    });
    return url;
  }

  /**
   * Get peer answers to a shared exercise (edukamu-wordwall, etc.)
   * @param {string} url - Yaml url
   * @param {string} course - Course ID
   * @returns {[]} List of answers
   */
  async getPeerAnswers(url, course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "getPeerAnswers_gen2");

      func({ url, course })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Assign peer review to us or get previously assigned
   * @param {string} url - Question YAML url
   * @param {string} course - Course ID
   * @returns
   */
  async assignPeerReview(url, course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "assignPeerReview_gen2");

      func({ url, course })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Submit peer review for other user
   * @param {string} url - Question YAML url
   * @param {string} course - Course ID
   * @param {number} grade - Grade 0-5
   * @param {string} comment - Review comment
   * @returns
   */
  async submitPeerReview(url, course, grade, comment) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "submitPeerReview_gen2");

      func({ url, course, grade, comment })
        .then((res) => {
          if (res?.data.userAnswer) {
            this.addAnswer(res.data.userAnswer);
            this.updateAnswerCallbacks();
          }

          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get peer reviews for our answer
   * @param {string} url - Question YAML url
   * @param {string} course - Course ID
   * @returns
   */
  async getPeerReviewResult(url, course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "getPeerReviewResult_gen2");

      func({ url, course })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveCourseSchedule(course, id, schedule) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "saveCourseSchedule_gen2");

      func({ course, id, schedule })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveExerciseMessages(course, question, userId, message) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "saveExerciseMessages_gen2");

      func({ course, question, userId, message })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get number of vouchers for this course.
   * Role: superadmin
   * @param {*} course - The course ID
   * @returns {{count: number, redeemed: number}}
   */
  async getVoucherCount(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "getVoucherCount_gen2");

      func({ course: course })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Create more vouchers for this course.
   * Role: superadmin
   * @param {*} course - The course ID
   * @param {*} count - Number of vouchers to create
   * @returns {boolean} Success
   */
  async createMoreVouchers(course, count) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "createMoreVouchers_gen2");

      func({ course: course, count: count })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Download vouchers for this course.
   * Role: superadmin
   * @param {*} course - The course ID
   * @returns {{}} JSON tree of vouchers.
   */
  async downloadVouchers(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "downloadVouchers_gen2");

      func({ course: course })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get pod list from azure kubernetes cluster
   * @param {*} course - The course ID, used for role check
   * @returns kubernetes API response
   */
  async kubernetesTest(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "kubernetesTest_gen2");

      func({ course: course })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async setCourseCompletedMessageRead(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "setCourseCompletedMessageRead_gen2");

      func({ course })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async customSignInLink(email) {
    return new Promise((resolve, reject) => {
      const actionSettings = {
        url: "http://localhost:3000/test/janne",
        handleCodeInApp: true,
      };


      const func = httpsCallable(this.functions, "sendSignInLink_gen2");

      func({ email, actionSettings })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async generateApiKey(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "generateApiKey_gen2");

      func({ course })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async revokeApiKey(course, prefix) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "revokeApiKey_gen2");

      func({ course, prefix })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get 'course_register' entries for specific course id.
   * @param {string} course - The course ID
   * @returns {object} - The filtered table.
   */
  async getCourseRegister(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "getCourseRegister_gen2");

      func({ course: course })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get user roles for specific course.
   * @param {string} course - The course ID
   * @returns {object} - List of roles.
   */
  async getUserRoles(course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "getUserRoles_gen2");

      func({ course: course })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get dictionary of courses user has registered to or completed,
   * and certificates earned with some basic info about each course.
   * @returns {Promise<{ courses: {}, certificates: {} }>}
   */
  async getUserCourses() {
    return new Promise((resolve, reject) => {
      const func = httpsCallable(this.functions, "getUserCourses_gen2");
      func()
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Custom implementation for password reset email
   *
   * @returns {Promise<boolean>}
   */
  async sendPasswordResetEmail(email, course) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "sendPasswordResetEmail_gen2");

      func({ email, course })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Create a PDF course certificate from a template.
   * @param {{}} course - Course
   * @param {null|{}} templateData - Data to use for lookup for inserting into template
   * @param {null|{}} overrideFields - Fields to use instead of course yaml
   * @returns {Promise<Uint8Array>}
   */
  async createCourseCertificate(course, templateData = null, overrideFields = null, returnimg = false) {
    return new Promise((resolve, reject) => {
      const func = httpsCallable(this.functions, "createCourseCertificate_gen2");
      if (!templateData) {
        templateData = {
          user: {
            name: this.auth.currentUser.displayName,
            id: this.auth.currentUser.uid
          },
        };
      }

      func({ course: course.id, templateData: templateData, overrideFields: overrideFields, returnimg: returnimg})
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Send a certificate to email of current user (admin).
   * @param {{ id: string, name: string }} course - Course
   * @param {string} email - Email to send to
   * @param {[{filename: string, path: string}]} files - Files to attach
   * @returns {Promise}
   */
  async sendCertificate(course, email, files) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "sendCertificate_gen2");

      func({ course: course.id, email: email, courseName: course.name, files: files })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Log error to /firebase_error/{course}.
   * @param {string} course
   * @param {string} componentid
   * @param {object} errordata
   * @returns
   */
  async logExerciseError(course, componentid, errormessage, stacktrace) {
    return new Promise((resolve, reject) => {

      const func = httpsCallable(this.functions, "logExerciseError_gen2");

      func({ course, componentid, errormessage, stacktrace })
        .then((result) => {
          if (result.data) {
            return resolve("Error saved");
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get course YAML (mainly for v2_catalogue)
   * @param {*} course
   */
  async getCourseYaml(course) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseYaml_gen2");
      get({ course })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // getOnlyCourseYaml
  /**
     * Get course YAML (mainly for v2_catalogue)
     * @param {*} domainname
     * @param {*} pathname
     */
  async getOnlyCourseYaml(domainname, pathname) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getOnlyCourseYaml_gen2");
      get({ domainname, pathname })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get decrypted nationalid
   * @param {string} nationalid
   * @returns {string} decrypted nationalid
   */
  async doDeCryptData(nationalid) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "doDeCryptData_gen2");
      get({ nationalid })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
          return null;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get crypted nationalid
   * @param {string} nationalid
   * @returns {string} encrypted nationalid
   */
  async doEnCryptData(nationalid) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "doEnCryptData_gen2");
      get({ nationalid })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
          return null;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get history (every attempt) for answers from 'v2_user_answers_history' for user.
   * @returns {Promise<*>}
   */
  async getUserAnswerHistory(courseID, userID) {
    return new Promise((resolve, reject) => {
      //return resolve("ebin")

      const get = httpsCallable(this.functions, "getUserAnswerHistory");
      get({ courseID, userID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Write page events (similar to Analytics-events) to Realtime Database
   * @param {object} pageEvent
   * @param {string} courseID
   * @param {string} userID
   * @param {string} urlName
   * @returns {[]} List of answers
   */
  /** Default pageEvent:
   * @param {string} type,
   * @param {string} urlName,
   * @param {number} timestamp,
   * @param {number} timeSpent,
   * @param {number} timeSpentTotal,
   * @param {string} timeSpentHuman,
   * @param {string} timeSpentTotalHuman,
   * @param {string} fromComponent
   */
  writePageEvents(pageEvent, courseID, userID, urlName) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "writePageEvents");

      get({ pageEvent, courseID, userID, urlName })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get page events (similar to Analytics-events) from Realtime Database
   * @param {string} eventType - What page events to fetch
   * @param {string} courseID - Contains
   * @param {string} userID - What page events to fetch
   * @param {object} fetchParam - What page events to fetch
   * @returns {[]} List of events
   */
  async getPageEvents(eventType, courseID, userID, fetchParam) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getPageEvents");

      get({ eventType, courseID, userID, fetchParam })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get page events (similar to Analytics-events) from Realtime Database
   * @param {string} eventType - What page events to fetch
   * @param {string} courseID - Contains
   * @param {string} userID - What page events to fetch
   * @param {object} fetchParam - What page events to fetch
   * @returns {[]} List of events
   */
  async getAllCoursePageEvents(courseID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getAllCoursePageEvents");

      get({ courseID })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get course reference data to initialize things
   * @param {string} courseID - current course ID
   * @returns {[]} List of events
   */
  async getCourseReference(courseID, onlyTags = false) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseReference");

      get({ courseID, onlyTags })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Write user profiling data to Realtime Database
   * @param {object} data
   * @param {string} userID
   * @returns {*} message
   */
  writeUserProfilingData(profilingData, courseID, userID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "writeUserProfilingData");

      get({ profilingData, courseID, userID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get course reference data to initialize things
   * @param {string} courseID - current course ID
   * @returns {[]} List of events
   */
  async getUserProfilingData(courseID, userID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserProfilingData");

      get({ courseID, userID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get course baseline data to initialize things
   * @param {string} courseID - current course ID
   * @param {string} dataPath - resource to be fetched
   * @returns {[]} List of events
   */
  async getCourseBaseline(courseID, dataPath) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getCourseBaseline");

      get({ courseID, dataPath })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get user online status for course data to show user state on BlockTable
   * @param {string} courseID - current course ID
   * @returns {[]} List of events
   */
  async getUserOnlineStatus(courseID, userID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserOnlineStatus");

      get({ courseID, userID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get groups
   * @param {string} courseID - current course ID
   * @returns {[]} List of events
   */
  async getUserGroups(courseID, groupID, userID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserGroups");

      get({ courseID, groupID, userID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get groups
   * @param {string} courseID
   * @param {string} groupID
   * @param {object} groupData
   * @returns {[]} List of groups
   */
  async createOrEditUserGroup(courseID, groupID, groupData) {
    return new Promise((resolve, reject) => {

      const update = httpsCallable(this.functions, "createOrEditUserGroup");

      update({ courseID, groupID, groupData })
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  /**
   * Delete group
   * @param {string} courseID - current course ID
   * @param {string} groupID - current group ID
   * @returns {object}
   */
  async deleteUserGroup(courseID, groupID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "deleteUserGroup");

      get({ courseID, groupID })
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  /**
   * Get flags from Realtime Database
   * @param {string} User ID
   * @param {string} Course ID
   * @returns {object} Contains all flags user has gotten
   */
  async getFlags(courseID, userID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getFlags");

      get({ courseID, userID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Update flags in Realtime Database
   * @param {string} User ID
   * @param {string} Course ID
   * @returns {object} Contains all flags user has gotten
   */
  async updateFlagStatus(status, userID, courseID, sectionType, sectionID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "updateFlagStatus");

      get({ status, userID, courseID, sectionType, sectionID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get user notifications data
   * @param {string} userID
   * @param {string} courseID
   * @param {string} dateKey
   * @param {string} notificationKey
   * @returns {[]}
   */
  async getUserCourseNotifications(userID, courseID, dateKey, notificationKey) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserCourseNotifications");

      get({ userID, courseID, dateKey, notificationKey })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get user notifications keys
   * @param {string} userID
   * @param {string} courseID
   * @returns {[]}
   */
  async getUserCourseNotificationsKeys(userID, courseID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserCourseNotificationsKeys");

      get({ userID, courseID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get AI model information from Realtime Database
   * @returns {object} Contains model data
   */
  async getAIModelInfo() {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getAIModelInfo");

      get()
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get user new notifications amount
   * @param {string} userID
   * @param {string} courseID
   * @returns {[]}
   */
  async getUserNewNotificationsAmount(userID, courseID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserNewNotificationsAmount");

      get({ userID, courseID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          return reject("Empty");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get user's global notifications
   * @param {string} userID
   * @returns {[]}
   */
  async getUserGlobalNotifications(userID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserGlobalNotifications");

      get({ userID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          return null;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get user's course pinned notifications
   * @param {string} userIDv
   * @param {string} courseID
   * @returns {[]}
   */
  async getUserCoursePinnedNotifications(userID, courseID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserCoursePinnedNotifications");

      get({ userID, courseID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          return null;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Update user notification
   * @param {string} userID
   * @param {string} courseID
   * @param {string} dateKey
   * @param {string} notifKey
   * @param {string} targetKey
   * @param {string} targetValue
   * @param {boolean} global
   * @param {boolean} pinned
   * @returns {object}
   */
  async updateUserNotification(userID, courseID, dateKey, notifKey, targetKey, targetValue, global, pinned) {
    return new Promise((resolve, reject) => {

      const update = httpsCallable(this.functions, "updateUserNotification");

      update({ userID, courseID, dateKey, notifKey, targetKey, targetValue, global, pinned })
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  /**
   * add new user notification
   * @param {string} userID
   * @param {string} dateKey
   * @param {string} notificationID
   * @param {object} newNotification
   * @param {string} courseID
   * @param {boolean} isGlobal
   * @param {boolean} isPinned

   * @returns {boolean}
   */

  async addNewUserNotification(userID, dateKey, notificationID, newNotification, courseID, isGlobal, isPinned) {
    return new Promise((resolve, reject) => {

      const add = httpsCallable(this.functions, "addNewUserNotification");

      add({ userID, dateKey, notificationID, newNotification, courseID, isGlobal, isPinned })
        .then((result) => {
          if (result) {
            return resolve(result);
          }
          return reject("Failed");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get user recommendations
   * @param {string} userID
   * @param {string} courseID
   * @param {boolean} isRead
   * @returns {[]}
   */
  async getUserRecommendations(userID, courseID, isRead) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getUserRecommendations");

      get({ userID, courseID, isRead })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          return reject("No Recommendations");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * add a new user recommedation
   * @param {string} userID
   * @param {string} courseID
   * @param {string} materialID
   * @param {boolean} isCourseRecommendation
   * @param {object} newRecommendation
   * @returns {{}}
   */
  async addNewUserRecommendation(userID, courseID, materialID, isCourseRecommendation, newRecommendation) {
    return new Promise((resolve, reject) => {

      const add = httpsCallable(this.functions, "addNewUserRecommendation");

      add({ userID, courseID, materialID, isCourseRecommendation, newRecommendation })
        .then((result) => {
          if (result) {
            return resolve(result);
          }
          return reject("Failed");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Update user recommendation
   * @param {string} userID
   * @param {string} courseID
   * @param {string} materialID
   * @param {string} targetValue
   * @param {object} updateValues
   * @returns {boolean}
   */
  async updateUserRecommendation(userID, courseID, materialID, targetValue, updateValues) {
    return new Promise((resolve, reject) => {

      const update = httpsCallable(this.functions, "updateUserRecommendation");

      update({ userID, courseID, materialID, targetValue, updateValues })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          return null;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get all Edukamu courses
   * @param {*} course
   */
  async getAllCourses(courseID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getAllCourses");
      get({ courseID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @param {string} courseID
   * @param {string} userID
   */
  async getTeacherAccessibleUsers(courseID, userID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getTeacherAccessibleUsers");
      get({ courseID, userID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {string} courseID
   */
  async getSkillTree(courseID) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "getSkillTree");
      get({ courseID })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("No data found!");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  azureFunctionsAPI(runFunction) {
    return new Promise((resolve, reject) => {

      const get = httpsCallable(this.functions, "azureFunctionsAPI");

      get(runFunction)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  /**
   * sendResetPasswordEmails
   * @param {string} emailAddr
   * @param {string} browserAddr
   */
  async sendResetPasswordEmails(emailAddr, browserAddr) {
    return new Promise((resolve, reject) => {
      var lang = this.auth.languageCode;

      const get = httpsCallable(this.functions, "sendResetPasswordEmails_gen2");
      get({ emailAddr, browserAddr, lang })
        .then((result) => {
          return resolve(true);
        })
        .catch((error) => {
          reject(error);
          return null;
        });
    });
  }

  /**
   *
   * @param {*} course
   */
  async sendBadgeEmail(courseID, badgename) {
    return new Promise((resolve, reject) => {

      const send = httpsCallable(this.functions, "sendBadgeEmail_gen2");
      send({ courseID, badgename })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          reject("Invalid data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  /**
   *
   * @param {*} course
   */
  async checkSentBadge(courseID, badgename) {
    return new Promise((resolve, reject) => {

      const send = httpsCallable(this.functions, "checkSentBadge_gen2");
      send({ courseID, badgename })
        .then((result) => {
          if (result.data) {
            return resolve(result.data);
          }
          return false;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Send verification email to current user
   * sendEmailConfirmation
   * @param {string} browserAddr
   * @param {*} resend should another email be sent if at least one has already been sent? Usable again 60 seconds from previous request.
   */

  async sendEmailConfirmation(browserAddr, resend) {
    return new Promise((resolve, reject) => {
      var lang = this.auth.languageCode;
      var user = this.auth.currentUser;
      var emailAddr = user.email;
      if (user) {

        const get = httpsCallable(this.functions, "sendEmailConfirmation");
        get({ emailAddr, browserAddr, lang, resend }).then(() => {
          resolve();
        })
          .catch((error) => {
            reject();
          });
      } else {
        // minuutin resend aika palautuu tässä
        resolve();
      }
    }
    );
  }

  async isSuperAdmin() {
    return new Promise((resolve, reject) => {

      const send = httpsCallable(this.functions, "isSuperAdmin_gen2");
      send()
        .then((result) => {
          if (result) {
            return resolve(result);
          }
          return resolve(false);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  async getHasRequiredAccomplishments(courses) {
    return new Promise((resolve, reject) => {

      const send = httpsCallable(this.functions, "getHasRequiredAccomplishments_gen2");
      send(courses)
        .then((result) => {
          if (result) {
            return resolve(result);
          }
          return resolve(false);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }
}

export default Firebase;
